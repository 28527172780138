import { CurrencyTypes, LanguageTypes, TimeZonesEnum } from '@ep/shared';
import { OfferTypes, LocationStatusTypeEnum } from '@shared/enums';

export interface Location {
  LocationId?: number | null;
  MerchantId?: number | null;
  Name?: string | null;
  Address?: string | null;
  PhoneNumber?: string | null;
  LanguageType?: LanguageTypes | null;
  CurrencyType?: CurrencyTypes | null;
  TimeZoneType?: TimeZonesEnum | null;
  OfferType?: OfferTypes | null;
  StatusType?: LocationStatusTypeEnum | null;
  UtcOffsetHour?: number | null;
}
