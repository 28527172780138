import { LocationState } from '@app/resources/ngrx/states';
import { locationAdapter } from '@app/resources/ngrx/adapters';
import { createReducer, on } from '@ngrx/store';
import { LocationActions } from '@app/resources/ngrx/actions';

import * as fromLocationAdapter from '@app/resources/ngrx/adapters';

export const initialLocationModuleState: LocationState = locationAdapter.getInitialState({
  location: null,
  hasLoaded: false,
});

export const _locationReducer = createReducer(
  initialLocationModuleState,
  on(LocationActions.upsertLocation, (state, { payload }) => {
    console.log('reducer!!!');
    console.log(payload);
    return fromLocationAdapter.locationAdapter.upsertOne(payload.location, state);
  }),
  on(LocationActions.selectLocation, (state) => {
    return Object.assign({
      ...state,
      location: state.location,
    });
  })
);
