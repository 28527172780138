import { Component, inject } from '@angular/core';
import { EpTableComponent, EPTableConfig } from '@ep/shared';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Report } from '@app/resources/services';
import { selectReports } from '@app/resources/ngrx/selectors';
import { ReportActions } from '@app/resources/ngrx/actions';
import { ReportModuleActions } from '@app/areas/reports/store';

@Component({
  selector: 'app-reports-table',
  standalone: true,
  imports: [EpTableComponent],
  templateUrl: './reports-table.component.html',
})
export class ReportsTableComponent {
  private readonly store = inject(Store);

  protected config: EPTableConfig<Report> = {
    header: 'reports',
    columns: [
      {
        header: 'Name',
        field: 'NamePrivate',
        columnType: 'string',
        defaultValue: 'Report',
      },
      {
        header: 'Description',
        field: 'Display',
        columnType: 'string',
        defaultValue: 'Daily Sales',
      },
    ],
    style: {},
    globalFilterFields: ['Name'],
    actions: {
      rowClick: (report: Report) => {
        this.store.dispatch(
          ReportModuleActions.navigateExistingReport({
            payload: { reportId: report.ReportId },
          })
        );
      },
    },
  };

  protected reports$: Observable<Report[]> = this.store.select(selectReports);
}
