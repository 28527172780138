<p-panel
  header="Details"
  class="transaction-basic-details-display-card"
  data-cy="transaction-basic-details-display-card"
>
  <div class="transaction-basic-details-display">
    <div class="transaction-basic-details-display-column transaction-basic-details-display-row-0"></div>
    <div class="transaction-basic-details-display-column transaction-basic-details-display-row-1">
      <div class="transaction-basic-details">
        <div class="transaction-basic-details-row">
          <label>Employee</label>
          <span>{{ employee() }}</span>
        </div>
        <ng-container *ngIf="transaction()?.Reviews?.[0] as firstReview; else noReview">
          <div class="transaction-basic-details-row">
            <label>Rating</label>
            <div class="transactions-feedback" [ngClass]="getReviewBadge(firstReview?.Score).cssClass">
              <span>{{ getReviewBadge(firstReview?.Score).value }}</span>
            </div>
          </div>
        </ng-container>

        <ng-template #noReview>
          <div *ngIf="isMember" class="transaction-basic-details-row">
            <label>Rating</label>
            <div class="transactions-feedback" [ngClass]="getReviewBadge(null).cssClass">
              <span>{{ getReviewBadge(null).value }}</span>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</p-panel>
