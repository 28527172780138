import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, take, tap } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { isNotNullOrUndefined } from '@ep/shared';
import { MembersApiService } from '../services';
import { Store } from '@ngrx/store';
import { selectCurrentConsumer } from '@app/resources/ngrx/selectors';
import { ConsumerActions, RouterActions } from '@app/resources/ngrx/actions';
import { Consumer } from '@app/resources/services';
import { MemberLoadingService } from '../services/member-loading.service';

@Injectable()
export class MemberRouteResolver {
  private readonly membersApiService = inject(MembersApiService);
  private readonly store = inject(Store);
  private readonly loadingService = inject(MemberLoadingService);

  resolve(route: ActivatedRouteSnapshot): Observable<Consumer> {
    let consumerId = parseInt(route.paramMap.get('consumerId') ?? '');
    if (isNaN(consumerId)) this.store.dispatch(RouterActions.navigateByUrl({ payload: { path: 'not-found' } }));
    this.store.dispatch(ConsumerActions.consumerById({ payload: { consumerId } }));

    return this.store.select(selectCurrentConsumer).pipe(
      take(1),
      switchMap(() => {
        return this.membersApiService.getMember(consumerId).pipe(
          tap((member) => {
            if (!member) {
              this.store.dispatch(RouterActions.navigateByUrl({ payload: { path: 'not-found' } }));
            } else {
              this.loadingService.setLoadingStatus(false);
              this.store.dispatch(ConsumerActions.upsertConsumer({ payload: { consumer: member } }));
              this.store.dispatch(ConsumerActions.consumerById({ payload: { consumerId: member.ConsumerId } }));
            }
          })
        );
      }),
      filter(isNotNullOrUndefined)
    );
  }
}
