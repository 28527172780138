import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Location } from '@shared/models';

enum LocationActionTypes {
  UPSERT_LOCATION = 'Upsert Location',
  SELECT_LOCATION = 'Select Location',
}

export const LocationActions = createActionGroup({
  source: 'Location',
  events: {
    [LocationActionTypes.UPSERT_LOCATION]: props<{ payload: { location: Location } }>(),
    [LocationActionTypes.SELECT_LOCATION]: emptyProps(),
  },
});
