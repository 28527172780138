import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ConsumerState } from '@app/resources/ngrx/states';
import { isNotNullOrUndefined } from '@ep/shared';
import { selectConsumerEntities, selectOrderEntities } from '@app/resources/ngrx/adapters';
import { getOrderEntities } from '@app/resources/ngrx/selectors/order.selector';

export const selectConsumerState = createFeatureSelector<ConsumerState>('consumer');
export const getConsumerEntities = createSelector(selectConsumerState, selectConsumerEntities);

export const getSelectedConsumerId = (state: ConsumerState) => {
  return state.selectedConsumerId;
};
export const selectCurrentConsumerId = createSelector(selectConsumerState, getSelectedConsumerId);

export const hasConsumersLoaded = createSelector(selectConsumerState, (state) => state.hasLoaded);

export const selectConsumers = createSelector(
  getConsumerEntities,
  hasConsumersLoaded,
  (consumerEntities, hasLoaded) => {
    if (!hasLoaded) return null;
    return Object.values(consumerEntities).filter(isNotNullOrUndefined);
  }
);

export const selectConsumersSortedByName = createSelector(
  getConsumerEntities,
  hasConsumersLoaded,
  (consumerEntities, hasLoaded) => {
    if (!hasLoaded) return null;
    return Object.values(consumerEntities)
      .filter(isNotNullOrUndefined)
      .sort((a, b) => {
        const aIsValued = a.NameFirst?.toUpperCase() === 'VALUED' && a.NameLast?.toUpperCase() === 'CUSTOMER';
        const bIsValued = b.NameFirst?.toUpperCase() === 'VALUED' && b.NameLast?.toUpperCase() === 'CUSTOMER';

        if (aIsValued && !bIsValued) {
          return 1;
        }
        if (!aIsValued && bIsValued) {
          return -1;
        }
        const result = (a.NameFirst ?? '').localeCompare(b.NameFirst ?? '');
        if (result !== 0) {
          return result;
        }
        return (a.NameLast ?? '').localeCompare(b.NameLast ?? '');
      });
  }
);

export const selectCurrentConsumer = createSelector(
  getConsumerEntities,
  selectCurrentConsumerId,
  (consumerEntities, consumerId) => {
    return consumerEntities[consumerId ?? 0];
  }
);

export const selectConsumerOrderPayments = createSelector(
  getOrderEntities,
  selectCurrentConsumer,
  (orderEntities, consumer) => {
    if (!isNotNullOrUndefined(consumer)) return [];

    return consumer.OrderPayments?.map((op) => {
      return {
        ...op,
        Order: orderEntities[op.OrderId],
      };
    });
  }
);
