import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LocationState } from '../states/location.state';
import { isNotNullOrUndefined } from '@ep/shared';
import { selectLocationEntities } from '../adapters/location.adapter';

export const selectLocationState = createFeatureSelector<LocationState>('location');
export const getLocationEntities = createSelector(selectLocationState, selectLocationEntities);
export const hasLocationLoaded = createSelector(selectLocationState, (state) => state.hasLoaded);

export const selectLocation = createSelector(getLocationEntities, hasLocationLoaded, (locationEntities, hasLoaded) => {
  console.log(locationEntities);
  if (!hasLoaded) return null;
  return Object.values(locationEntities).filter(isNotNullOrUndefined);
});
