import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { RootState } from '@app/resources/ngrx/states';
import {
  _applicationProfileReducer,
  _authenticationReducer,
  _consumerReducer,
  _deviceReducer,
  _employeeReducer,
  _invoiceReducer,
  _loyaltyProgramReducer,
  _orderReducer,
  _orderReviewReducer,
  _questionReducer,
  _reportReducer,
  _locationReducer,
  hydrationMetaReducer,
} from '@app/resources/ngrx/reducers';
import { routerReducer } from '@ngrx/router-store';
import { _credentialsReducer } from '@app/resources/ngrx/reducers/credentials.reducer';

export const reducers: ActionReducerMap<RootState> = {
  applicationProfile: _applicationProfileReducer,
  authentication: _authenticationReducer,
  consumer: _consumerReducer,
  credentials: _credentialsReducer,
  device: _deviceReducer,
  employee: _employeeReducer,
  invoice: _invoiceReducer,
  loyaltyProgram: _loyaltyProgramReducer,
  order: _orderReducer,
  orderReview: _orderReviewReducer,
  questions: _questionReducer,
  report: _reportReducer,
  router: routerReducer,
  // location: _locationReducer,
};

export const metaReducers: MetaReducer[] = [hydrationMetaReducer];
