<p-panel header="Orders" class="existing-member-recent-display-card" data-cy="existing-member-recent-display-card">
  <p-dataView
    #dv
    [value]="orderPayments()"
    [rows]="4"
    *ngIf="orderPayments() as transactions"
    [paginator]="true"
    [totalRecords]="totalRecords"
    [lazy]="true"
    (onPage)="pageChangeEvent($event)"
    emptyMessage=""
  >
    <ng-template pTemplate="list" let-transactions>
      <ng-container *ngIf="!isLoading && isUserOnline; else skeletonTemplate">
        <div *ngFor="let item of transactions; let first = first">
          <div class="order-wrapper" (click)="navigateToOrder(item)">
            <div class="flex flex-column gap-2 p-3">
              <div class="order-details-row">
                <div class="align-items-center d-flex gap-3 justify-content-between w-100">
                  <div *ngIf="item?.OrderGuid" class="w-13rem">
                    <span class="align-items-center d-flex gap-2">
                      <span class="font-semibold text-secondary"> Order #{{ item?.OrderGuid | truncateGuid }} </span>
                    </span>
                  </div>
                  <div *ngIf="!item?.OrderGuid" class="order-skeleton skeleton"></div>

                  <div class="font-medium">
                    {{ item.AmountTotal | currency }}
                  </div>
                  <span class="font-medium">
                    {{ item.StartDateTime | date : 'MM/dd/YYYY, hh:mm aa' }}
                  </span>
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <div class="align-items-center d-flex gap-3">
                  <span class="font-medium">Rating:</span>
                  <div class="member-transactions-feedback" [ngClass]="getReviewBadge(item).cssClass">
                    <span>{{ getReviewBadge(item).value }}</span>
                  </div>
                </div>
                <div
                  class="align-items-center order-view-btn d-flex flex-column h-2rem justify-content-center w-2"
                  (click)="navigateToOrder(item)"
                >
                  <span>View</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-template #skeletonTemplate>
        <div *ngIf="isUserOnline">
          <div *ngFor="let item of totalRecordsArray" class="flex flex-column">
            <div class="order-skeleton skeleton"></div>
          </div>
        </div>

        <div *ngIf="!isUserOnline" class="align-items-center d-flex flex-column gap-5">
          <div class="d-flex justify-content-around">
            <span> Data not available right now. Please retry in a moment. </span>
          </div>

          <div class="align-items-center d-flex flex-column h-2rem justify-content-center w-2 pb-5">
            <div>
              <p-button
                (onClick)="fetchOrdersReviewsData(lastPageClicked)"
                size="small"
                id="save-employee-button"
                data-cy="save-employee-button"
              >
                Retry
              </p-button>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-template>
  </p-dataView>
</p-panel>
