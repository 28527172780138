import { Component, computed, input, OnInit } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { FormatPhonePipe } from '@shared/pipes';
import { CurrencyPipe, DatePipe, NgClass, NgIf } from '@angular/common';
import { PanelModule } from 'primeng/panel';
import { Order } from '@app/resources/services';

@Component({
  selector: 'app-existing-transaction-basic-details',
  standalone: true,
  imports: [FaIconComponent, FormatPhonePipe, NgIf, PanelModule, CurrencyPipe, DatePipe, NgClass],
  templateUrl: './existing-transaction-basic-details.component.html',
  styleUrl: './existing-transaction-basic-details.component.scss',
})
export class ExistingTransactionBasicDetailsComponent implements OnInit {
  transaction = input.required<Order>();

  employee = computed(() => {
    const employee = this.transaction().Employee;
    return employee ? `${employee.NameFirst} ${employee.NameLast}` : 'EMPLOYEE';
  });

  protected isMember: boolean = false;

  ngOnInit(): void {
    this.isMember = this.transaction().OrderPayments.some(
      (orderPayment) => orderPayment.ConsumerId != null && orderPayment.ConsumerId !== 0
    );
  }

  protected getReviewBadge(score?: number | null): { value: string; cssClass: string } {
    if (score === 5) {
      return { value: 'Great', cssClass: 'transactions-great' };
    } else if (score === 0) {
      return { value: 'Poor', cssClass: 'transactions-poor' };
    } else if (score === undefined) {
      return { value: '', cssClass: 'rating-skeleton skeleton' };
    } else if (score === null && this.isMember) {
      return { value: 'Not Answered', cssClass: 'transactions-not-answered' };
    } else {
      return { value: '', cssClass: '' };
    }
  }
}
