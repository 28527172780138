export function isPrecise(num: number | string) {
  const numString = typeof num === 'number' ? String(num) : num;
  if (!numString.includes('.')) return true;

  const decimalPrecision = numString.split('.')[1]?.length;
  return 0 < decimalPrecision && decimalPrecision <= 2;
}

export function getEnvironment(): 'staging' | 'preprod' | 'prod' | '' {
  switch (location.origin) {
    case 'http://localhost:4200':
      return 'staging';
    case 'https://staging-portal.enrollandpay.com':
    case 'https://nabdashboard-staging.enrollandpay.com':
      return 'staging';
    case 'https://preproduction-portal.enrollandpay.com':
      return 'preprod';
    case 'https://portal.enrollandpay.com':
    case 'https://netelement-portal.enrollandpay.com':
    case 'https://nabdashboard.enrollandpay.com':
    case 'https://merchant.eagleprocessingrewards.com':
    case 'https://gspdashboard.enrollandpay.com':
      return 'prod';
    default:
      return '';
  }
}
