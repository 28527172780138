import { Component, DestroyRef, inject, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { EpTableComponent, EPTableConfig, LoadingSpinnerComponent } from '@ep/shared';
import { Store } from '@ngrx/store';
import { Order } from '@app/resources/services';
import { selectOrderQty, selectOrders, selectOrdersSortedByStartDate } from '@app/resources/ngrx/selectors';
import { TransactionModuleActions } from '@app/areas/transactions/store';
import { mapToTransactionTableDisplays } from '@app/areas/transactions/utils';
import { catchError, map } from 'rxjs/operators';
import { TransactionTableDisplay } from '@app/areas/transactions/models';
import { AsyncPipe, NgIf } from '@angular/common';
import { TransactionApiService } from '@app/areas/transactions/services';
import { Observable, of } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { OrderActions } from '@app/resources/ngrx/actions';
import { TransactionLoadingService } from '../../services/transaction-loading.service';

@Component({
  selector: 'app-transactions-table',
  standalone: true,
  imports: [EpTableComponent, AsyncPipe, LoadingSpinnerComponent, NgIf],
  template: `
    <div *ngIf="!(isLoading$ | async)" class="p-4">
      <app-ep-table
        [config]="config"
        [data]="transactions$ | async"
        (pageChange)="pageChangeEvent($event)"
        [totalRecords]="totalRecords"
        data-cy="transactions-table"
      />
    </div>
    <app-loading-spinner *ngIf="isLoading$ | async" [message]="'Loading...'" class="text-center" />
  `,
  styleUrl: './transactions-table.component.scss',
})
export class TransactionsTableComponent implements OnInit {
  private readonly store = inject(Store);
  private readonly transactionsApiService = inject(TransactionApiService);
  private readonly destroyedRef: DestroyRef = inject(DestroyRef);
  private readonly loadingService = inject(TransactionLoadingService);

  public isLoading$ = this.loadingService.isLoading$;

  protected totalRecords: number = 0;
  protected transactions$: Observable<TransactionTableDisplay[] | null> = of(null);
  protected config: EPTableConfig<TransactionTableDisplay> = {
    header: 'transactions',
    lazy: true,
    pagination: {
      rows: 10,
      showCurrentPageReport: true,
    },
    columns: [
      {
        header: 'Date',
        field: 'StartDateTime',
        columnType: 'date',
        dateFormat: 'MM-dd-YYYY',
        defaultValue: null,
        disableSorting: true,
      },
      {
        header: 'Employee',
        field: 'Employee',
        columnType: 'string',
        defaultValue: 'Missing employee',
        disableSorting: true,
      },
      {
        header: 'Status',
        field: 'StatusType',
        columnType: 'string',
        defaultValue: 'Open',
        disableSorting: true,
      },
      {
        header: 'Enrollment',
        field: 'EnrollmentType',
        columnType: 'string',
        defaultValue: 'Declined',
        disableSorting: true,
      },
      {
        header: 'Total',
        field: 'AmountTotal',
        columnType: 'price',
        defaultValue: 0,
        disableSorting: true,
      },
      {
        header: 'Rating',
        field: 'Score',
        columnType: 'categorical',
        defaultValue: 'Poor',
        disableSorting: true,
        fieldToValueMap: this.mapOrderReviewStatusToValue,
        severity: this.getOrderReviewStatusSeverity,
      },
    ],
    style: {},
    globalFilterFields: ['Employee', 'NameLast'],
    actions: {
      rowClick: (transaction: Order) => {
        this.loadingService.setLoadingStatus(true);
        this.store.dispatch(
          TransactionModuleActions.navigateExistingTransaction({
            payload: { orderId: transaction.OrderId },
          })
        );
      },
    },
  };

  public ngOnInit(): void {
    this.loadingService.setLoadingStatus(false);
    this.transactions$ = this.store.select(selectOrdersSortedByStartDate).pipe(map(mapToTransactionTableDisplays));
    this.fetchTransactions(1);
    this.fetchTotalRecords();
  }

  protected fetchTotalRecords(): void {
    this.store
      .select(selectOrderQty)
      .pipe(
        catchError(() => of()),
        takeUntilDestroyed(this.destroyedRef)
      )
      .subscribe((totalRecords: number) => {
        this.totalRecords = totalRecords;
      });
  }

  public pageChangeEvent(event: { first: number; rows: number }): void {
    const pageNumber = event.first === 0 ? 1 : Math.floor(event.first / 10) + 1;
    this.fetchTransactions(pageNumber);
  }

  protected fetchTransactions(pageNumber: number): void {
    this.transactionsApiService
      .getTransactions(pageNumber, 10)
      .pipe(
        catchError(() => of()),
        takeUntilDestroyed(this.destroyedRef)
      )
      .subscribe(({ orders, totalTransactions }) => {
        this.store.dispatch(OrderActions.loadOrdersSuccess({ payload: { orders, totalTransactions } }));
      });
  }

  protected mapOrderReviewStatusToValue(orderReview: TransactionTableDisplay): string {
    if ((orderReview.Score === null || orderReview.Score === undefined) && orderReview.IsMember) {
      return 'Not Answered';
    } else if (orderReview.Score === null) {
      return '';
    } else if (orderReview.Score === 0) {
      return 'Poor';
    } else if (orderReview.Score === 5) {
      return 'Great';
    } else {
      return 'Mixed';
    }
  }

  protected getOrderReviewStatusSeverity(orderReview: TransactionTableDisplay): string {
    if ((orderReview.Score === null || orderReview.Score === undefined) && orderReview.IsMember) {
      return 'primary';
    } else if (orderReview.Score === null) {
      return 'empty';
    } else if (orderReview.Score === 0) {
      return 'danger';
    } else if (orderReview.Score === 5) {
      return 'success';
    } else {
      return 'warning';
    }
  }
}
